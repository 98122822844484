import { useEffect, useMemo, useState } from "react";
import TextTransition from "react-text-transition";

type TextLoopProps = {
  texts: string[];
};

export const TextLoop = ({ texts }: TextLoopProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentText = useMemo<string>(() => {
    if (texts.length <= 0) {
      return "";
    }

    return texts[currentIndex % texts.length];
  }, [currentIndex, texts]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((index) => index + 1);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <TextTransition>
      <span>{currentText}</span>
    </TextTransition>
  );
};
