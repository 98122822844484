import ScrollToTop from "react-scroll-up";
import { Header } from "../../components/Header";
import { PageHelmet } from "../../components/Helmet";
import { CurriculumVitae } from "../../components/CurriculumVitae";
import { Services } from "./components/Services";
import { Portfolio } from "./components/Portfolio";
import { Footer } from "../../components/Footer";
import { FiChevronUp } from "react-icons/fi";
import { Contact } from "./components/Contact";
import { TextLoop } from "./components/TextLoop";

export const LandingPage = () => {
  const title = "About Me";
  const description = `
            Experienced front-end developer with a strong background in React.js, TypeScript, and JavaScript. 
            Skilled in creating highly interactive web applications, having a deep understanding of the latest web development trends and technologies. 
            Working with TypeScript, allows me to write safer and more scalable code using the best practices in this field. 
            I am able to work and adapt to any development team, and I can help bring projects to successful completion.
        `;
  const slideList = [
    {
      textPosition: "text-left",
      category: "Welcome to my World",
      description: "",
      buttonText: "",
      buttonLink: "",
    },
  ];

  return (
    <div className="active-dark">
      <PageHelmet pageTitle="Ciprian Draghici" />

      <Header homeLink="/" color="color-black" />
      {/* Start Slider Area   */}
      <div id="home" className="fix bg_image--presentation">
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {slideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      <h1 className="title">
                        Hi, I’m Ciprian Draghici <br />
                        <TextLoop
                          texts={[
                            "Front-end Developer",
                            "UI Developer",
                            "Web3 Developer",
                          ]}
                        />
                      </h1>
                      <h2>based in EUROPE.</h2>
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120 bg_image">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/profile.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <CurriculumVitae />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start Service Area  */}
      <div id="service" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <h2 className="title">Services</h2>
                  <p>
                    Software development services to businesses and
                    organizations. These services can include custom software
                    development, web and mobile app development, UI/UX design,
                    IT management, and specialized trainings.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <Services />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start Portfolio Area */}
      <div id="portfolio" className="fix">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Latest projects</h2>
                    <p>
                      I am eager to highlight my technical capabilities,
                      industry expertise, and experience in delivering
                      high-quality software solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <Portfolio />
              </div>
              {/*<div className="row">*/}
              {/*  <div className="col-lg-12">*/}
              {/*    <div className="view-more-btn mt--60 mt_sm--30 text-center">*/}
              {/*      <a*/}
              {/*        className="rn-button-style--2 btn-solid"*/}
              {/*        href="/portfolio"*/}
              {/*      >*/}
              {/*        <span>View More</span>*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Contact Area */}
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_image bg_image--contact">
          <Contact />
        </div>
      </div>
      {/* End COntact Area */}

      <Footer />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160} style={{ bottom: 100 }}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};
