import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export const CurriculumVitae = () => {
  const tab1 = "Main skills";
  const tab3 = "Experience";
  const tab4 = "Education";

  return (
    <div>
      {/* Start Tabs Area */}
      <div className="tabs-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Tabs>
                <TabList className={"tab-style--1"}>
                  <Tab>{tab1}</Tab>
                  <Tab>{tab3}</Tab>
                  <Tab>{tab4}</Tab>
                </TabList>

                <TabPanel>
                  <div className="single-tab-content">
                    <ul>
                      <li>
                        <span className="font-weight-bolder">
                          Web (React.js, TypeScript, JavaScript){" "}
                        </span>
                        <span> - Development</span>
                      </li>
                      <li>
                        <span className="font-weight-bolder">Web3 dApps</span>
                        <span>
                          {" "}
                          - Blockchain (MultiversX - ex. Elrond Network)
                        </span>
                      </li>
                      <li>
                        <span className="font-weight-bolder">
                          NPM packages{" "}
                        </span>
                        <span> - NPM</span>
                      </li>
                      <li>
                        <span className="font-weight-bolder">
                          Github workflows{" "}
                        </span>
                        <span> - CI/CD</span>
                      </li>
                      <li>
                        <span className="font-weight-bolder">
                          User experience design{" "}
                        </span>
                        <span> - UI/UX</span>
                      </li>
                      <li>
                        <span className="font-weight-bolder">
                          Interactive layout{" "}
                        </span>
                        <span> - Drag&Drop, 2D Transformations</span>
                      </li>
                    </ul>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="single-tab-content">
                    <ul>
                      <li>
                        <a href="/service">
                          Sr. Front-End Engineer (Web3 dApp Developer)
                          <span> - Contractor </span>
                        </a>{" "}
                        2022 - Current
                      </li>
                      <li>
                        <a href="/service">
                          Sr. Software Engineer<span> - Shoreline.io</span>
                        </a>{" "}
                        2021 - 2022
                      </li>
                      <li>
                        <a href="/service">
                          Sr. Front-end Engineer<span> - WebFactory GmbH</span>
                        </a>{" "}
                        2020 - 2022
                      </li>
                      <li>
                        <a href="/service">
                          Front-end Engineer<span> - WebFactory GmbH</span>
                        </a>{" "}
                        2015 - 2020
                      </li>
                      <li>
                        <a href="/service">
                          Jr. Software Engineer
                          <span> - Continental Automotive Systems </span>
                        </a>{" "}
                        2013- 2015
                      </li>
                    </ul>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="single-tab-content">
                    <ul>
                      <li>
                        <a href="/service">
                          Master, Computer Engineering in Industrial
                          Applications.
                          <span> - "Lucian Blaga" University of Sibiu</span>
                        </a>{" "}
                        2016 - 2018
                      </li>
                      <li>
                        <a href="/service">
                          Bachelor's degree, Multimedia Systems Engineering
                          <span> - "Lucian Blaga" University of Sibiu </span>
                        </a>{" "}
                        2012 - 2016
                      </li>
                    </ul>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* End Tabs Area */}
    </div>
  );
};
