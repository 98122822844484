import ScrollToTop from "react-scroll-up";
import { PageHelmet } from "../../components/Helmet";
import { Header } from "../../components/Header";
import { SocialShare } from "../../constants/social";
import React, { useMemo, useState } from "react";
import ModalVideo from "react-modal-video";
import { FiChevronUp } from "react-icons/fi";
import { Footer } from "../../components/Footer";
import { useParams } from "react-router-dom";
import { getProjectDetails } from "../../utils/getProjectDetails";

export const ProjectDetails = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { id } = useParams<{ id: string }>();

  const projectDetails = useMemo(() => {
    return getProjectDetails(id);
  }, [id]);

  const openModal = () => {
    setIsModalOpened(true);
  };

  const closeModal = () => {
    setIsModalOpened(false);
  };

  return (
    <div className="active-dark">
      <PageHelmet pageTitle="Portfolio Details" />

      <Header color="color-black" homeLink="/" />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  <a href={projectDetails?.website}>
                    {projectDetails?.pageTitle}
                  </a>
                </h2>
                <p className="mx-5">{projectDetails?.pageSubTitle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--50 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                  <h3 style={{ color: "#707979" }}>
                    {projectDetails?.inCollaborationWith}
                  </h3>
                  <h1>{projectDetails?.projectTitle}</h1>
                  <p className="subtitle">{projectDetails?.projectSubTitle}</p>
                  {projectDetails?.description.map((paragraph) => (
                    <p> {paragraph} </p>
                  ))}
                  <div className="portfolio-view-list d-flex flex-wrap">
                    <div className="port-view">
                      <span>Branch</span>
                      <h4>{projectDetails?.branch}</h4>
                    </div>

                    <div className="port-view">
                      <span>Project Types</span>
                      <h4>{projectDetails?.projectType}</h4>
                    </div>

                    <div className="port-view">
                      <span>Website</span>
                      <h4>
                        <a
                          href={projectDetails?.website}
                          style={{ textDecoration: "underline" }}
                        >
                          {projectDetails?.pageTitle}
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="portfolio-thumb-inner mt-5">
                  <div className="thumb position-relative mb--30">
                    <img
                      src={projectDetails?.videoImagePreview}
                      alt="video-preview"
                    />
                    {projectDetails?.videoId && (
                      <>
                        <ModalVideo
                          channel="youtube"
                          isOpen={isModalOpened}
                          videoId={projectDetails?.videoId ?? ""}
                          onClose={closeModal}
                        />
                        <button
                          className="video-popup position-top-center"
                          onClick={openModal}
                        >
                          <span className="play-icon"></span>
                        </button>
                      </>
                    )}
                  </div>

                  {projectDetails?.photos.map((photo) => (
                    <div className="thumb mb--30">
                      <img src={photo} alt="photo" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </div>
  );
};
