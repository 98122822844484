export const Footer = () => {
  return (
    <div
      className="footer-style-2 ptb--10 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/">
                  <img
                    className="standard-logo-symbol"
                    src="/assets/images/logo/logo-symbol.png"
                    alt="Ciprian Draghici"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>Copyright © 2022 Ciprian Draghici. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
