import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/PageScrollTop";
import { LandingPage } from "./pages/landing/LandingPage";
import { Error404 } from "./pages/Error404";
import { ProjectDetails } from "./pages/project-details/ProjectDetails";

function App() {
  return (
    <BrowserRouter basename={"/"}>
      <PageScrollTop>
        <Switch>
          <Route exact path="/" component={LandingPage} />

          <Route exact path={`/project-details/:id`} component={ProjectDetails} />

          <Route path="*" component={Error404} />
        </Switch>
      </PageScrollTop>
    </BrowserRouter>
  );
}

export default App;
