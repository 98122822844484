import { SocialShare } from "../../../constants/social";
import { MdEmail } from "react-icons/md";

export const Contact = () => {
  return (
    <div className="contact-form--1 d-flex justify-content-end">
      <div className="container">
        <div className="row row--35 align-items-start">
          <div className="col-lg-6 order-2 order-lg-1">
            <div className="logo">
              <img src="/assets/images/logo/logo.png" alt="Ciprian Draghici" />
            </div>
          </div>
          <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
            <div className="section-title text-left mb--50 justify-content-center d-flex flex-wrap flex-column float-right">
              <h2 className="title">{"Contact me"}</h2>
              <p className="description d-flex flex-column">
                I am available for freelance work. Connect with me via email,
                social networks or telegram
                <div>
                  <MdEmail />
                  <a className="ml-2" href="mailto:admin@example.com">
                    admin@cipriandraghici.ro
                  </a>{" "}
                </div>
                <ul className="social-share d-flex liststyle mt-2">
                  {SocialShare.map((val, i) => (
                    <li key={i}>
                      <a href={`${val.link}`}>{val.Social}</a>
                    </li>
                  ))}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
